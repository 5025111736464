import { DialogTitle, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { createAddPlaylists } from "../api/playlist";
import { NotePrivacy, Season } from "../types/constants";
import ModalBase from "./ModalBase";

import AddIcon from '@mui/icons-material/Add';
import { playerUI } from "../api/nba";
import { useAuth } from "../context/auth-context";
import { useDataContext } from "../context/nba-data";
import { boxScoreDb, gameBoxDto, teamsDto } from "../types/dto";
import PlayersSelector from "./menu/PlayersSelector";
import TeamSelector from "./menu/TeamSelector";
import NoteText from "./NoteText";
import { useUserDataStore } from "../context/playlist-store";
import { getGameBox } from "../api/stat";
import { games } from "../types/ui";

import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)({
  borderLeft: '1px solid rgba(81, 81, 81, 1)',  
  borderRight: '1px solid rgba(81, 81, 81, 1)',
  padding: '7px',
});


interface Column {
    label: string;
    width?: string;
    borderRight?: boolean;
}

const width = '50px';
const align = 'center';
const borderStyle = {borderLeft: '1px solid rgba(81, 81, 81, 1)', borderRight: '1px solid rgba(81, 81, 81, 1)'}
const columns: readonly Column[] = [
    { label: 'Name', width: '200px' },
    { label: 'Min', width },
    { label: '+/-', width, borderRight: true},
    { label: 'Pts', width, },
    { label: '2P', width, },
    { label: '3P', width, },
    { label: 'FT', width, borderRight: true},
    { label: 'Ast', width, },
    { label: 'Ast Pts', width, borderRight: true},
    { label: 'Live TOV', width, },
    { label: 'Dead TOV', width, borderRight: true},
    { label: 'Off Reb', width, },
    { label: 'Def Reb', width, borderRight: true},
    { label: 'Blk', width, },
    { label: 'Stl', width, },
    { label: 'Dead TOV', width, },
    { label: 'Def FT', width, },
    { label: 'Foul', width, },
];


interface gameBoxModal {
    isButtonOutlined: boolean;
    season: Season;
    gameId: number;
    gameBox: gameBoxDto | null;
    // receives optional data param, if not present will do the API call and check
}
export default function GameBoxModal(props: gameBoxModal) {
    const [open, _setOpen] = useState(false);
    const [gameBox, setGameBox] = useState(props.gameBox);

    const setOpen = (newVal: boolean) => {
        if (!props.gameBox && newVal) {
            getGameBox(props.season, props.gameId).then(res => {
                if (res) {
                    setGameBox(res);
                    _setOpen(newVal);
                } else {
                    window.open(
                        `https://www.nba.com/game/${awayTeam.triCode.toUpperCase()}-vs-${homeTeam.triCode.toUpperCase()}-00${game.gameId}/box-score`,
                        "_blank",
                    )
                }
            });
        } else {
            _setOpen(newVal)
        }
    }

    const data = useDataContext();
    const game = data.gamesBySeason[props.season].find(x => x.gameId === props.gameId) as games;
    const homeTeam = data.teams[game.homeTeamId];
    const awayTeam = data.teams[game.awayTeamId];

    const renderRow = (playerId: string, box: boxScoreDb) => {
        const player = data.playersBySeason[props.season].find(x => x.playerId.toString() === playerId) as playerUI;
        return <TableRow hover tabIndex={-1} key={playerId}>
            <StyledTableCell align={align}>{player.name}</StyledTableCell>
            <StyledTableCell align={align}>{box.min}</StyledTableCell>
            <StyledTableCell align={align}>{box.plusMinus > 0 ? "+" : ""}{box.plusMinus}</StyledTableCell>
            <StyledTableCell align={align}>{`${box.fgm * 2 + box['3pm'] + box.ftm}`}</StyledTableCell>
            <StyledTableCell align={align}>{`${box.fgm - box["3pm"]}/${box.fga - box["3pa"]}`}</StyledTableCell>
            <StyledTableCell align={align}>{`${box["3pm"]}/${box["3pa"]}`}</StyledTableCell>
            <StyledTableCell align={align}>{`${box.ftm}/${box.fta}`}</StyledTableCell>
            <StyledTableCell align={align}>{box.ast}</StyledTableCell>
            <StyledTableCell align={align}>{box.ptsAst}</StyledTableCell>
            <StyledTableCell align={align}>{box.tovLive}</StyledTableCell>
            <StyledTableCell align={align}>{box.tov-box.tovLive}</StyledTableCell>
            <StyledTableCell align={align}>{box.oreb}</StyledTableCell>
            <StyledTableCell align={align}>{box.dreb}</StyledTableCell>
            <StyledTableCell align={align}>{box.blk}</StyledTableCell>
            <StyledTableCell align={align}>{box.stl}</StyledTableCell>
            <StyledTableCell align={align}>{box.tovForced-box.stl}</StyledTableCell>
            <StyledTableCell align={align}>{box.defFTs}</StyledTableCell>
            <StyledTableCell align={align}>{box.foul}</StyledTableCell>
            {/* {"3pa":4,"3pm":3,"ast":2,"blk":0,"fga":8,"fgm":6,"fta":0,"ftm":0,"min":"12:17","stl":4,"tov":0,"dreb":6,
            "foul":2,"oreb":1,"defFTs":1,"ptsAst":4,"tovLive":0,"plusMinus":16,"tovForced":4} */}
        </TableRow>
    }
    return (
        <ModalBase
            open={open}
            setOpen={setOpen}
            fullWidth={true}
            content={
                <>
                    {/* <DialogTitle sx={{ paddingTop: '0px' }}>Box Score</DialogTitle> */}
                    <DialogContentText>
                        {/* <Grid container rowSpacing={2} textAlign='center'>
                            <Grid item xs={12}>
{gameBox && JSON.stringify(gameBox)}
                            </Grid>
                        </Grid> */}
                        {
                            gameBox &&
                            // <TableContainer sx={{ maxHeight: '50%' }}>
                            <Table stickyHeader aria-label="sticky table" >
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center" colSpan={3}>{homeTeam.teamCity} {homeTeam.teamName}</StyledTableCell>
                                        <StyledTableCell align="center" style={borderStyle} colSpan={4}>
                                            Scoring
                                        </StyledTableCell>
                                        <StyledTableCell align="center" style={borderStyle} colSpan={4}>
                                            Creating
                                        </StyledTableCell>
                                        <StyledTableCell align="center" style={borderStyle} colSpan={2}>
                                            Rebound
                                        </StyledTableCell>
                                        <StyledTableCell align="center" style={borderStyle} colSpan={5}>
                                            Defense
                                        </StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <StyledTableCell
                                                key={column.label}
                                                align={align}
                                                style={{ minWidth: column.width, maxWidth: column.width, borderRight: column.borderRight ? '1px solid rgba(81, 81, 81, 1)' : 'none' }}
                                            >
                                                {column.label}
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        Object.keys(gameBox.homePlayerBox)
                                        .sort((a, b) => {
                                            const aMin = gameBox.homePlayerBox[a].min;
                                            const bMin = gameBox.homePlayerBox[b].min
                                            const [minA, secA] = aMin.split(":").map(Number);
                                            const [minB, secB] = bMin.split(":").map(Number);                                            
                                            return minB * 60 + secB - (minA * 60 + secA);
                                        })
                                        .map(id => renderRow(id, gameBox.homePlayerBox[id]))
                                    }

                                    </TableBody>
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center" colSpan={3}>{awayTeam.teamCity} {awayTeam.teamName}</StyledTableCell>
                                        <StyledTableCell align="center" style={borderStyle} colSpan={4}>
                                            Scoring
                                        </StyledTableCell>
                                        <StyledTableCell align="center" style={borderStyle} colSpan={4}>
                                            Creating
                                        </StyledTableCell>
                                        <StyledTableCell align="center" style={borderStyle} colSpan={2}>
                                            Rebound
                                        </StyledTableCell>
                                        <StyledTableCell align="center" style={borderStyle} colSpan={5}>
                                            Defense
                                        </StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <StyledTableCell
                                                key={column.label}
                                                align={align}
                                                style={{ width: column.width, borderRight: column.borderRight ? '1px solid rgba(81, 81, 81, 1)' : 'none' }}
                                            >
                                                {column.label}
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                    <TableBody>
                                    {
                                        Object.keys(gameBox.awayPlayerBox)
                                        .sort((a, b) => {
                                            const aMin = gameBox.awayPlayerBox[a].min;
                                            const bMin = gameBox.awayPlayerBox[b].min
                                            const [minA, secA] = aMin.split(":").map(Number);
                                            const [minB, secB] = bMin.split(":").map(Number);                                            
                                            return minB * 60 + secB - (minA * 60 + secA);
                                        })
                                        .map(id => renderRow(id, gameBox.awayPlayerBox[id]))
                                    }
                                </TableBody>
                            </Table>
                            //   </TableContainer>                    
                        }
                    </DialogContentText>
                </>
            }
            button={
                <Button
                    color="secondary"
                    fullWidth
                    variant={props.isButtonOutlined ? 'outlined' : "text"}
                >
                    Box Score
                </Button>
            }
            actions={
                <>
                    <Button color="secondary">
                        Close
                    </Button>
                </>
            }
        />
    );
}
