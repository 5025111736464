import VideoIcon from "@mui/icons-material/Videocam";
import Grid from "@mui/material/Grid";
import { useEffect, useMemo, useState } from "react";
import { useDataContext } from "../context/nba-data";

import { searchGames } from "../utils/game-utils";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import {
  NavigateFunction,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { League, MAX_PLAYLIST_PLAY_COUNT, Season } from "../types/constants";
import { userGameViewsDto } from "../types/dto";
import { games } from "../types/ui";
import { createGameVideoUrl } from "../utils/url-utils";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import GameBoxModal from "./GameBoxModal";
interface userGameProps {
  showUserStats: boolean;
  game: games;
  navigate: NavigateFunction;
  gameViews: userGameViewsDto;
  season: Season;
}

const logoHeight = 100;
export default function UserGame(props: userGameProps) {
  const { gameViews, game, season, navigate } = props;
  const userTotal = gameViews.userGameViews[game.gameId] ?? 0;
  const gameTotal = gameViews.totalGameViews[game.gameId] ?? 0;
  const gameNotes = gameViews.userNotes[game.gameId] ?? 0;
  const gameSaved = gameViews.userSaved[game.gameId] ?? 0;
  const globalGameNotes = gameViews.globalGameNotes[game.gameId] ?? 0;
  const globalGameViews = gameViews.globalGameViews[game.gameId] ?? 0;
  const homeWin = game.homePts > game.awayPts;
  const firstTeamId = homeWin ? game.homeTeamId : game.awayTeamId;
  const secondTeamId = homeWin ? game.awayTeamId : game.homeTeamId;
  let gameText = homeWin
    ? `${game.homePts} v. ${game.awayPts}`
    : `${game.awayPts} @ ${game.homePts}`;
  const data = useDataContext();
  const homeTeam = data.teams[game.homeTeamId];
  const awayTeam = data.teams[game.awayTeamId];
  return (
    <Grid
      container
      sx={{
        maxWidth: "500px",
        margin: 'auto',
        backgroundColor: "#202020",
        textAlign: "center",
        paddingTop: "10px",
        borderRadius: "15px",
      }}
    >
      <Grid item xs={12}>
        <img
          src={`/team-logos/${game.awayTeamId}.svg`}
          //src={`https://cdn.nba.com/logos/nba/${game.awayTeamId}/primary/L/logo.svg`}
          height={logoHeight}
          style={{ verticalAlign: "bottom" }}
        />
        <div
          style={{
            display: "inline-flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            height: logoHeight,
            marginLeft: "5px",
            marginRight: "5px",
          }}
        >
          <div>
            {/* <Typography variant="h6">{gameText}</Typography> */}
            <Typography variant="h6">
              {/* <Typography variant="h6" component='span' bgcolor={awayTeam.teamColor}
                sx={{
                  padding: "0px 5px",
                  borderRadius: "4px",
                }}
              // sx={{ textShadow: '-0.5px -0.5px 0 black, 0.5px -0.5px 0 black, -0.5px  0.5px 0 black, 0.5px  0.5px 0 black;' }}
              >
                {awayTeam.teamName}
              </Typography> */}
              <Typography
                variant="h6"
                component="span"
                sx={{ color: homeWin ? "gray" : "white" }}
              >
                {' ' + game.awayPts}
              </Typography>
              <Typography variant="h6" component="span" color="white">
                {" "}
                @{" "}
              </Typography>
              <Typography
                variant="h6"
                component="span"
                sx={{ color: homeWin ? "white" : "gray" }}
              >
                {game.homePts + ' '}
              </Typography>
              {/* <Typography variant="h6" component='span' bgcolor={homeTeam.teamColor}
                sx={{
                  padding: "0px 5px",
                  borderRadius: "4px",
                }}
              // sx={{ textShadow: '-0.2px -0.2px 0 black, 0.2px -0.2px 0 black, -0.5px  0.2px 0 black, 0.2px  0.2px 0 black;' }}
              >
                {homeTeam.teamName}
              </Typography> */}
            </Typography>
          </div>
          <div>
            <Typography variant="body2" style={{ color: "lightgray" }}>
              {game.gameDate}
            </Typography>
          </div>
          {/* {!props.showUserStats && (
            <Typography variant="body2">{gameTotal} videos</Typography>
          )} */}
        </div>
        <img
          src={`/team-logos/${game.homeTeamId}.svg`}
          // src={`https://cdn.nba.com/logos/nba/${game.homeTeamId}/primary/L/logo.svg`}
          height={logoHeight}
          style={{ verticalAlign: "bottom" }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          borderBottom: "1px solid rgba(81, 81, 81, 1)",
          paddingBottom: "10px",
        }}
      >
        {props.showUserStats && (
          <Typography variant="body2" component="p">
            <Typography variant="body2" component="span" paddingX="10px">
              You:
            </Typography>
            <Typography
              variant="body2"
              component="span"
              paddingX="10px"
              borderRight="1px solid rgba(81, 81, 81, 1)"
            >
              {gameTotal > 0 ? Math.round((userTotal / gameTotal) * 100) : 0}%
              {/* ({userTotal}/{gameTotal}) */} viewed
            </Typography>
            <Typography
              variant="body2"
              component="span"
              paddingX="10px"
              borderRight="1px solid rgba(81, 81, 81, 1)"
            >
              {gameSaved} saved
            </Typography>
            <Typography variant="body2" component="span" paddingX="10px">
              {gameNotes} posts
            </Typography>
          </Typography>
        )}
        <Typography variant="body2" component="p" marginTop="5px">
          <Typography variant="body2" component="span" paddingX="10px">
            World:
          </Typography>
          <Typography
            variant="body2"
            component="span"
            paddingX="10px"
            borderRight="1px solid rgba(81, 81, 81, 1)"
          >
            {globalGameViews} plays viewed
          </Typography>
          <Typography variant="body2" component="span" paddingX="10px">
            {globalGameNotes} posts
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={props.showUserStats ? 4 : 6}>
        <GameBoxModal season={season} gameId={game.gameId} gameBox={null} isButtonOutlined={false} />
        {/* <Button
          color="secondary"
          fullWidth
          variant="text"
          onClick={() =>
            window.open(
              `https://www.nba.com/game/${awayTeam.triCode}-vs-${homeTeam.triCode}-00${game.gameId}/box-score`,
              "_blank",
            )
          }
        >
          Box Score
        </Button> */}
      </Grid>
      <Grid
        item
        xs={props.showUserStats ? 4 : 6}
        sx={{ borderLeft: "1px solid rgba(81, 81, 81, 1)" }}
      >
        <Button
          color="secondary"
          fullWidth
          variant="text"
          startIcon={<VideoIcon color="secondary" />}
          onClick={() =>
            navigate(createGameVideoUrl(League.NBA, season, game.gameId, false))
          }
        >
          {props.showUserStats ? "All" : "Watch"}
        </Button>
      </Grid>
      {props.showUserStats && (
        <Grid item xs={4} sx={{ borderLeft: "1px solid rgba(81, 81, 81, 1)" }}>
          <Button
            color="secondary"
            fullWidth
            variant="text"
            startIcon={<VideoIcon color="secondary" />}
            onClick={() =>
              navigate(
                createGameVideoUrl(League.NBA, season, game.gameId, true),
              )
            }
          >
            Unviewed
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
