import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import HelpIcon from "@mui/icons-material/Help";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import { cloneElement, useEffect, useState } from "react";

interface modalBaseProps {
    open: boolean;
    setOpen: (newVal: boolean) => void;
    content: ReactJSXElement;
    button: ReactJSXElement;
    actions: ReactJSXElement;
    fullWidth?: boolean;
}
export default function ModalBase(props: modalBaseProps) {
    const [open, setOpen] = useState(props.open);
    const handleClose = () => props.setOpen(false);
    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);
    const onClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        props.setOpen(true)
    }
    const button = cloneElement(props.button, { onClick });
    return (
        <>
            {/* <Box onClick={(e) => } display='inline-block'> */}
            {button}
            {/* </Box> */}
            <Dialog
                maxWidth={props.fullWidth ? 'xl' : 'sm'}
                open={open}
                onClose={handleClose}
            >
                {
                    open && <>
                    <DialogContent>
                        {props.content}
                    </DialogContent>
                        <DialogActions onClick={handleClose}>
                            {props.actions}
                        </DialogActions>
                    </>
                }
                {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}

            </Dialog>
        </>
    );
}
