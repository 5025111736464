import { useEffect, useRef, useState } from "react";
// import logo from './logo.svg';
import Close from "@mui/icons-material/Close";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Drawer from "@mui/material/Drawer";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { playerUI } from "../api/nba";
import { useDataContext } from "../context/nba-data";
import { League, PlayerPlayTypes, Season, SortType, comparers, defShotPlayTypes, offShotPlayTypes } from "../types/constants";
import { pbpVideoDto, playerBoxDto, playerDto } from "../types/dto";
import { games } from "../types/ui";
import { createPlayerVideoUrl } from "../utils/url-utils";
import PlayerGameMenu from "./PlayerGameMenu";
import PlayersSelector from "./menu/PlayersSelector";
import ShotTypeFilter from "./menu/ShotTypeFilter";
import { getPlayerBox } from "../api/stat";

const drawerWidth: number = 600;

export interface playerWatchMenuProps {
  season: Season;
  player: playerDto;
  teamId: string;
  teamPlayerIds: Set<number>;
  closeDrawer: () => void;
  // lineups: lineupDto[];
}

function PlayerWatchMenu(props: playerWatchMenuProps) {
  const [selectedPlayers, setSelectedPlayers] = useState<playerUI[]>([]);
  const data = useDataContext();
  const [selectedShotTypes, setSelectedShotTypes] = useState([] as string[]);
  const [playTypes, _setSelectedPlayTypes] = useState(new Set(Object.values(PlayerPlayTypes)))
  const [offShots, setOffShots] = useState(true);
  const [defShots, setDefShots] = useState(true);
  const [sort, setSort] = useState(SortType.DESC);
  const [playerBox, setPlayerBox] = useState(null as playerBoxDto | null);

  const togglePlayType = (toToggle: PlayerPlayTypes) => {
    if (playTypes.has(toToggle)) playTypes.delete(toToggle);
    else playTypes.add(toToggle);
    _setSelectedPlayTypes(new Set(playTypes));
  }

  const addAndRemovePlayType = (toAdd: PlayerPlayTypes, toRemove: PlayerPlayTypes) => {
    playTypes.add(toAdd);
    playTypes.delete(toRemove);
    _setSelectedPlayTypes(new Set(playTypes));
  }

  const addPlayTypes = (playTypesToAdd: PlayerPlayTypes[]) => {
    playTypesToAdd.forEach(x => playTypes.add(x));
    _setSelectedPlayTypes(new Set(playTypes));
  }
  
  useEffect(() => {
    getPlayerBox(props.season, props.player.playerId).then(x => 
      {
        setPlayerBox(x)      
      });
  }, [props.season, props.player]);
  const [selectedGames, setSelectedGames] = useState([] as games[]);

  const drawerRef = useRef<HTMLDivElement>(null);

  const teamPlayers = data.playersBySeason[props.season].filter((x) =>
    props.teamPlayerIds.has(x.playerId) && x.playerId !== props.player.playerId
  );
  const playerGames = data.gamesBySeason[props.season].filter(x =>
    x.homePlayerIds.has(props.player.playerId) || x.awayPlayerIds.has(props.player.playerId)
  )
  const navigate = useNavigate();
  const onSubmit = () => {
    const playTypesForUse = new Set(playTypes);
    if (!offShots) offShotPlayTypes.forEach(x => playTypesForUse.delete(x));
    if (!defShots) defShotPlayTypes.forEach(x => playTypesForUse.delete(x));
    const shotTypesForUse = [] as number[];
    if (selectedShotTypes.length < Object.keys(data.shotTypes).length) {
      selectedShotTypes.forEach(type => {
        const emats = data.shotTypes[type];
        emats.forEach(emat => shotTypesForUse.push(emat));
      });
    }
    return navigate(createPlayerVideoUrl({
      playTypes: Array.from(playTypesForUse),
      league: League.NBA,
      season: props.season,
      playerId: props.player.playerId,
      teamId: props.teamId,
      gameIds: selectedGames.map(x => x.gameId),
      lineupPlayerIds: selectedPlayers.map(x => x.playerId),
      shotTypes: shotTypesForUse,
      newOnly: false, sort
    }))
  }
  const maxWidth = '100%';
  const width = '100%';// (1280 + 32) + "px";
  const textAlign = 'left';
  const margin = 'auto';
  const formControlStyle = {
    border: '1px solid grey', borderRadius: '5px', paddingX: '8px', paddingBottom: '8px', textAlign: 'left' 
  }
  const drawerContent = (
    <>
      <Toolbar
        sx={{
          position: "flex",
          width: "100%",
          justifyContent: "center",
          marginRight: "auto",
        }}
      >
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {props.player.name}
        </Typography>
        <IconButton
          onClick={props.closeDrawer}
          sx={{ position: "absolute", right: 5, top: 5 }}
        >
          <Close />
        </IconButton>
      </Toolbar>

      <Grid
        container
        justifyContent="center"
        sx={{ textAlign: "center", padding: "10px", rowGap: "10px" }}
      >
        <Grid item xs={12}>
          <PlayerGameMenu selectedGames={selectedGames}
          playerBox={playerBox}
            maxTags={10}
            setSelectedGames={games => {
              setSelectedGames(games)
            }}
            validGames={playerGames}
          />
        </Grid>
        <Grid item xs={12}>
          <PlayersSelector
            showAbbrs={true}
            players={teamPlayers}
            selectedPlayers={selectedPlayers}
            maxItems={4}
            label="Players on court"
            onChange={setSelectedPlayers}
          />
        </Grid>
        <Grid item xs={12}>
          <Accordion sx={{ margin }} disableGutters>
            <AccordionSummary sx={{
              maxWidth, width,
              textAlign,
            }}
              expandIcon={<ExpandMoreIcon />}
            >
              Play Types
            </AccordionSummary>
            <AccordionDetails sx={{ maxWidth, width, textAlign, margin }}>
              <Grid container>
                <Grid item xs={7} textAlign='right' paddingRight='5px'>
                  <FormControl component="fieldset" variant="standard" sx={{ ...formControlStyle }}>
                    <FormLabel component="legend">Offense (shots)</FormLabel>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox color='secondary' size='small' checked={offShots} onChange={() => setOffShots(!offShots)} />} label="Shots Taken" />
                      <ToggleButtonGroup
                        disabled={!offShots}
                        sx={{ margin: "auto", marginBottom: "10px" }}
                        color={offShots ? 'secondary' : 'info'}
                        value={playTypes.has(PlayerPlayTypes.MAKE) ?
                          (playTypes.has(PlayerPlayTypes.MISS) ? -1 : 1) : 0
                        }
                        size="small"
                        exclusive
                        onChange={(e, newVal) => {
                          if (newVal < 0) addPlayTypes([PlayerPlayTypes.MAKE, PlayerPlayTypes.MISS]);
                          else if (newVal === 0) addAndRemovePlayType(PlayerPlayTypes.MISS, PlayerPlayTypes.MAKE);
                          else if (newVal > 0) addAndRemovePlayType(PlayerPlayTypes.MAKE, PlayerPlayTypes.MISS);
                        }}
                      >
                        <ToggleButton value={1}>
                          Make
                        </ToggleButton>
                        <ToggleButton value={0}>
                          Miss
                        </ToggleButton>
                        <ToggleButton value={-1}>
                          Both
                        </ToggleButton>
                      </ToggleButtonGroup>
                      <ToggleButtonGroup
                        disabled={!offShots}
                        sx={{ margin: "auto", marginBottom: "10px" }}
                        color={offShots ? 'secondary' : 'info'}
                        value={playTypes.has(PlayerPlayTypes.TWOPT) ?
                          (playTypes.has(PlayerPlayTypes.THREEPT) ? -1 : 1) : 0
                        }
                        size="small"
                        exclusive
                        onChange={(e, newVal) => {
                          if (newVal < 0) addPlayTypes([PlayerPlayTypes.TWOPT, PlayerPlayTypes.THREEPT]);
                          else if (newVal === 0) addAndRemovePlayType(PlayerPlayTypes.THREEPT, PlayerPlayTypes.TWOPT);
                          else if (newVal > 0) addAndRemovePlayType(PlayerPlayTypes.TWOPT, PlayerPlayTypes.THREEPT);
                        }}
                      >
                        <ToggleButton value={1}>
                          2PT
                        </ToggleButton>
                        <ToggleButton value={0}>
                          3PT
                        </ToggleButton>
                        <ToggleButton value={-1}>
                          Both
                        </ToggleButton>
                      </ToggleButtonGroup>
                      <ToggleButtonGroup
                        disabled={!offShots}
                        sx={{ margin: "auto", marginBottom: "10px" }}
                        color={offShots ? 'secondary' : 'info'}
                        value={playTypes.has(PlayerPlayTypes.ASTD) ?
                          (playTypes.has(PlayerPlayTypes.UASTD) ? -1 : 1) : 0
                        }
                        size="small"
                        exclusive
                        onChange={(e, newVal) => {
                          if (newVal < 0) addPlayTypes([PlayerPlayTypes.ASTD, PlayerPlayTypes.UASTD]);
                          else if (newVal === 0) addAndRemovePlayType(PlayerPlayTypes.UASTD, PlayerPlayTypes.ASTD);
                          else if (newVal > 0) addAndRemovePlayType(PlayerPlayTypes.ASTD, PlayerPlayTypes.UASTD);
                        }}
                      >
                        <ToggleButton title='Assisted' value={1}>
                          Ast'd
                        </ToggleButton>
                        <ToggleButton title='Unassisted' value={0}>
                          Not
                        </ToggleButton>
                        <ToggleButton value={-1}>
                          Both
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={5} textAlign='left' paddingLeft='5px'>
                  <FormControl component="fieldset" variant="standard" sx={formControlStyle}>
                    <FormLabel component="legend">Other Offense</FormLabel>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox color='secondary' checked={playTypes.has(PlayerPlayTypes.AST)} onChange={() => togglePlayType(PlayerPlayTypes.AST)} />} label="Assist" />
                      <FormControlLabel control={<Checkbox color='secondary' checked={playTypes.has(PlayerPlayTypes.FT)} onChange={() => togglePlayType(PlayerPlayTypes.FT)} />} label="Drew FT" />
                      <FormControlLabel control={<Checkbox color='secondary' checked={playTypes.has(PlayerPlayTypes.TOV)} onChange={() => togglePlayType(PlayerPlayTypes.TOV)} />} label="TOV" />
                      <FormControlLabel control={<Checkbox color='secondary' checked={playTypes.has(PlayerPlayTypes.OREB)} onChange={() => togglePlayType(PlayerPlayTypes.OREB)} />} label="OREB" />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={7} marginTop='8px'textAlign='right' paddingRight='5px'>
                  <FormControl component="fieldset" variant="standard" sx={{...formControlStyle}}>
                    <FormLabel component="legend">Defense (shots)</FormLabel>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox color='secondary' size='small' checked={defShots} onChange={() => setDefShots(!defShots)} />} label="Shots Defended" />
                      <ToggleButtonGroup
                        disabled={!defShots}
                        sx={{ margin: "auto", marginBottom: "10px" }}
                        color={defShots ? 'secondary' : 'info'}
                        value={playTypes.has(PlayerPlayTypes.DMAKE) ?
                          (playTypes.has(PlayerPlayTypes.DMISS) ? -1 : 1) : 0
                        }
                        size="small"
                        exclusive
                        onChange={(e, newVal) => {
                          if (newVal < 0) addPlayTypes([PlayerPlayTypes.DMAKE, PlayerPlayTypes.DMISS]);
                          else if (newVal === 0) addAndRemovePlayType(PlayerPlayTypes.DMISS, PlayerPlayTypes.DMAKE);
                          else if (newVal > 0) addAndRemovePlayType(PlayerPlayTypes.DMAKE, PlayerPlayTypes.DMISS);
                        }}
                      >
                        <ToggleButton value={1}>
                          Make
                        </ToggleButton>
                        <ToggleButton value={0}>
                          Miss
                        </ToggleButton>
                        <ToggleButton value={-1}>
                          Both
                        </ToggleButton>
                      </ToggleButtonGroup>
                      <ToggleButtonGroup
                        disabled={!defShots}
                        sx={{ margin: "auto", marginBottom: "10px" }}
                        color={defShots ? 'secondary' : 'info'}
                        value={playTypes.has(PlayerPlayTypes.D2PT) ?
                          (playTypes.has(PlayerPlayTypes.D3PT) ? -1 : 1) : 0
                        }
                        size="small"
                        exclusive
                        onChange={(e, newVal) => {
                          if (newVal < 0) addPlayTypes([PlayerPlayTypes.D2PT, PlayerPlayTypes.D3PT]);
                          else if (newVal === 0) addAndRemovePlayType(PlayerPlayTypes.D3PT, PlayerPlayTypes.D2PT);
                          else if (newVal > 0) addAndRemovePlayType(PlayerPlayTypes.D2PT, PlayerPlayTypes.D3PT);
                        }}
                      >
                        <ToggleButton value={1}>
                          2PT
                        </ToggleButton>
                        <ToggleButton value={0}>
                          3PT
                        </ToggleButton>
                        <ToggleButton value={-1}>
                          Both
                        </ToggleButton>
                      </ToggleButtonGroup>
                      <ToggleButtonGroup
                        disabled={!defShots}
                        sx={{ margin: "auto", marginBottom: "10px" }}
                        color={defShots ? 'secondary' : 'info'}
                        value={playTypes.has(PlayerPlayTypes.DASTD) ?
                          (playTypes.has(PlayerPlayTypes.DUASTD) ? -1 : 1) : 0
                        }
                        size="small"
                        exclusive
                        onChange={(e, newVal) => {
                          if (newVal < 0) addPlayTypes([PlayerPlayTypes.DASTD, PlayerPlayTypes.DUASTD]);
                          else if (newVal === 0) addAndRemovePlayType(PlayerPlayTypes.DUASTD, PlayerPlayTypes.DASTD);
                          else if (newVal > 0) addAndRemovePlayType(PlayerPlayTypes.DASTD, PlayerPlayTypes.DUASTD);
                        }}
                      >
                        <ToggleButton title='Assisted' value={1}>
                          Ast'd
                        </ToggleButton>
                        <ToggleButton title='Unassisted' value={0}>
                          Not
                        </ToggleButton>
                        <ToggleButton value={-1}>
                          Both
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={5} marginTop='8px' textAlign='left' paddingLeft='5px'>
                  <FormControl component="fieldset" variant="standard" sx={formControlStyle}>
                    <FormLabel component="legend">Other Defense</FormLabel>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox color='secondary' checked={playTypes.has(PlayerPlayTypes.DBLK)} onChange={() => togglePlayType(PlayerPlayTypes.DBLK)} />} label="Block" />
                      <FormControlLabel control={<Checkbox color='secondary' checked={playTypes.has(PlayerPlayTypes.DFOUL)} onChange={() => togglePlayType(PlayerPlayTypes.DFOUL)} />} label="Foul" />
                      <FormControlLabel control={<Checkbox color='secondary' checked={playTypes.has(PlayerPlayTypes.DTOV)} onChange={() => togglePlayType(PlayerPlayTypes.DTOV)} />} label="Forced TOV" />
                      <FormControlLabel control={<Checkbox color='secondary' checked={playTypes.has(PlayerPlayTypes.DREB)} onChange={() => togglePlayType(PlayerPlayTypes.DREB)} />} label="DREB" />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <ShotTypeFilter
          selectedShotTypes={selectedShotTypes}
          setSelectedShotTypes={setSelectedShotTypes} />

        <Grid item xs={12}>
          <ToggleButtonGroup
            sx={{ margin: "auto", marginBottom: "10px" }}
            color='secondary'
            value={sort}
            size="small"
            exclusive
            onChange={(e, newVal) => setSort(newVal)}
          >
            <ToggleButton value={SortType.ASC}>
              Earliest
            </ToggleButton>
            <ToggleButton value={SortType.DESC}>
              Latest
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        <Grid item xs={6}>
          <Button
            variant="outlined"
            onClick={props.closeDrawer}
            color="secondary"
            fullWidth
          >
            Close
          </Button>
        </Grid>

        <Grid item xs={6}>
          <Button
            variant="contained"
            onClick={onSubmit}
            color="secondary"
            fullWidth
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );

  const theme = useTheme();
  const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <>
      <Drawer
        ref={drawerRef}
        sx={{
          "& .MuiDrawer-paper": {
            zIndex: 1202, // TODO fix this
            margin: "auto",
            marginBottom: "10px",
            maxWidth: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#343434",
          },
        }}
        anchor={greaterThanMid ? "top" : "bottom"}
        variant="persistent"
        open={true}
      >
        {drawerContent}
      </Drawer>
    </>
  );
}

export default PlayerWatchMenu;
